exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dla-zarzadcow-js": () => import("./../../../src/pages/dla-zarzadcow.js" /* webpackChunkName: "component---src-pages-dla-zarzadcow-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-realizacje-js": () => import("./../../../src/pages/realizacje.js" /* webpackChunkName: "component---src-pages-realizacje-js" */),
  "component---src-pages-uslugi-ogolnobudowlane-js": () => import("./../../../src/pages/uslugi-ogolnobudowlane.js" /* webpackChunkName: "component---src-pages-uslugi-ogolnobudowlane-js" */),
  "component---src-pages-wypozyczalnia-js": () => import("./../../../src/pages/wypozyczalnia.js" /* webpackChunkName: "component---src-pages-wypozyczalnia-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

