module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xs":"(max-width: 330px)","xs420":"(max-width: 425px)","xs550":"(max-width: 550px)","sm":"(max-width: 670px)","tablets":"(max-width: 780px)","md":"(max-width: 1000px)","l":"(max-width: 1500px)","xL":"(min-width: 2000px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Rento- wypożyczalnia sprzętów budowlanych","short_name":"Rento","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b5566b9d4a4a1f89841eea4282a89d49"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
